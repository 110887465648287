@charset "UTF-8";
body {
  --font-size: var(--font-size-scale-base);
  --text-color: var(--color);
  --text-color-rgb: var(--color-rgb);
  --text-color-hsl: var(--color-hsl);
  --link-color: var(--interactive);
  --link-color-rgb: var(--interactive-rgb);
  --link-color-hsl: var(--interactive-hsl);
  --bg-color: var(--background);
  --bg-color-rgb: var(--background-rgb);
  --bg-color-hsl: var(--background-hsl);
  --font-weight: var(--font-weight-regular);
  --line-height: var(--font-line-height-medium);
  --border-color: var(--color-secondary-06);
  background: var(--bg-color);
  color: var(--text-color);
  font-family: var(--font-family-base) !important;
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  --danger-alternative: #FDE0DB;
  --button-primary-hover-color: #396DC0; }

.br-header .header-search .br-input input:not(:focus),
.br-header.compact .header-search .br-input input:not(:focus) {
  background: var(--color-secondary-02) !important; }

.br-header .header-actions .header-links .br-list .br-item {
  color: var(--item-color) !important; }
  .br-header .header-actions .header-links .br-list .br-item.active {
    background: inherit;
    font-weight: var(--font-weight-bold);
    text-decoration: underline; }
  .br-header .header-actions .header-links .br-list .br-item:hover {
    background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover))); }

.br-avatar {
  display: inline-flex;
  align-items: center; }
  .br-avatar .image .notification {
    position: absolute;
    left: 25px;
    top: -8px;
    color: var(--color-danger);
    font-size: 18px;
    width: auto; }
  .br-avatar .image .badge {
    position: absolute;
    left: 26px;
    top: -8px;
    border-radius: 12px;
    padding: 4px 7px 6px 7px; }
  .br-avatar small {
    padding: 0 var(--spacing-scale-base); }

.notification-header small {
  display: none; }

@media (max-width: 576px) {
  .br-avatar small {
    display: none; }
  .notification-header small {
    display: block; } }

@media (max-width: 992px) {
  .br-header .header-logo .header-sign,
  .br-header .header-logo .br-divider {
    display: none; } }

.header-logo img {
  cursor: pointer; }

.br-header .header-actions .dropdown.show .br-list {
  display: block;
  z-index: var(--z-index-layer-1);
  position: absolute;
  box-shadow: var(--surface-shadow-sm);
  white-space: nowrap; }

.br-header .header-actions .header-functions .br-list .br-button {
  --button-size: auto;
  --button-font-size: var(--font-size-scale-base) !important;
  --button-font-weight: var(--font-weight-regular) !important;
  border-radius: 0 !important;
  justify-content: flex-start !important;
  padding: var(--spacing-scale-base) var(--spacing-scale-2x) !important;
  width: 100% !important; }
  .br-header .header-actions .header-functions .br-list .br-button .badge {
    position: absolute;
    margin-top: -18px;
    margin-left: 14px;
    padding: 2px 5px; }

.br-input input {
  border-radius: var(--input-radius) !important;
  padding: var(--spacing-scale-base) var(--spacing-scale-2x) !important;
  background-color: var(--color-secondary-01) !important;
  border-color: var(--gray-40) !important; }

.br-menu .menu-header .menu-title img {
  max-width: 134px; }

.br-breadcrumb {
  display: block;
  margin: var(--spacing-scale-base) 0;
  padding-top: 0;
  position: relative;
  width: 100%; }

.br-tag {
  height: 32px !important;
  padding: 0 var(--spacing-scale-base);
  line-height: normal;
  font-size: var(--font-size-scale-down-01);
  white-space: nowrap; }
  .br-tag span {
    text-transform: uppercase;
    margin: var(--spacing-scale-half); }
  .br-tag.privado {
    --tag-background: var(--color-highlight); }
  .br-tag.situacao {
    --tag-background: var(--color-warning);
    --tag-color: var(--text-color); }

.content-home {
  background-image: url("/assets/images/home_back.png") !important;
  background-repeat: no-repeat;
  background-position-x: auto;
  background-position-y: 100%;
  padding-bottom: var(--spacing-scale-2x);
  background-size: 100% auto;
  margin-left: -3%;
  width: 106%;
  padding: var(--spacing-scale-2x) 4%; }
  .content-home h1 strong span {
    color: var(--color-primary-default); }

.banner-home-img {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: var(--spacing-scale-2x);
  padding-right: var(--spacing-scale-4x);
  width: 100%; }

.img-banner {
  height: auto;
  padding: 0 15% var(--spacing-scale-2x) !important;
  width: auto; }

.content-home .img-banner {
  padding: 0 0 var(--spacing-scale-base) !important; }

.br-footer hr {
  margin-left: -3%;
  width: 106%; }

.br-notification {
  font-family: var(--font-family-base);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  display: none;
  background-color: var(--color-secondary-01) !important; }
  .br-notification.show {
    display: unset; }
  .br-notification .ui-overlaypanel {
    z-index: 9999 !important;
    position: fixed !important;
    display: block !important;
    right: 16px !important;
    left: unset !important;
    top: 96px !important; }
  .br-notification .notification-header {
    background-color: var(--color-secondary-01) !important; }
  .br-notification .notification-body {
    height: auto;
    max-height: calc(100vh - 200px);
    overflow-y: auto; }
    .br-notification .notification-body .br-list {
      padding: 0;
      margin: 0; }
      .br-notification .notification-body .br-list .br-item {
        --interactive-rgb: var(--blue-warm-vivid-70-rgb);
        --item-color: var(--interactive);
        cursor: pointer;
        border: 1px solid var(--item-background);
        padding: var(--spacing-scale-base) var(--spacing-scale-4x) !important;
        position: relative;
        white-space: normal; }
        .br-notification .notification-body .br-list .br-item:hover {
          background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)));
          text-decoration: underline; }
        .br-notification .notification-body .br-list .br-item i {
          padding-right: var(--spacing-scale-2x); }

.main-content {
  width: 100% !important;
  height: 100% !important; }

@media (min-width: 1280px) {
  .br-header .header-actions .header-links [data-toggle],
  .br-header .header-actions .header-functions [data-toggle] {
    display: none; } }

/*
.br-button {
  --button-background: transparent;
  --button-border: transparent;
  --button-color: var(--interactive);
  --button-radius: 100em;
  --button-font-size: var(--font-size-scale-up-01);
  --button-font-weight: var(--font-weight-semi-bold);
  --button-xsmall: 24px;
  --button-small: 32px;
  --button-medium: 40px;
  --button-large: 48px;
  --button-size: var(--button-medium);
  --button-padding: 0 var(--spacing-scale-3x);
  --button-img: 20px;
  --interactive-rgb: var(--rgb-primary-default);
  align-items: center;
  background-color: var(--button-background) !important;
  border: 1px solid var(--button-border) !important;
  border-radius: var(--button-radius) !important;
  color: var(--button-color) !important;
  cursor: pointer  !important;
  font-size: var(--button-font-size)  !important;
  font-weight: var(--button-font-weight)  !important;
  height: var(--button-size) !important;
  justify-content: center !important;
  text-align: center  !important;
  vertical-align: middle  !important;

  .ui-button-icon-left {
    padding: 0 var(--spacing-scale-2x) !important;
  }
  &.ui-button-text-icon-left .ui-button-text {
    padding: var(--spacing-scale-half) var(--spacing-scale-half) var(--spacing-scale-half) var(--spacing-scale-3x) !important;
  }

  &.circle,
  &.ui-widget.ui-button-icon-only.ui-button-rounded {
    width: 32px !important;
    height: 32px !important;
    border-color: transparent !important;
    background-color: transparent;
    justify-content: center;
    text-align: center;
    .ui-button-text {
      display: none;
    }
    .ui-button-icon,
    .ui-button-icon-left {
      padding: 0 !important;
      font-size: var(--font-size-scale-base);
    }
  }
}
*/
.ui-widget.ui-button,
.ui-button,
.ui-button.br-button {
  height: auto;
  width: auto;
  border-radius: 100em;
  padding: var(--spacing-scale-half) var(--spacing-scale-2x);
  display: flex;
  flex-wrap: nowrap;
  padding: 0 var(--spacing-scale-2x) var(--spacing-scale-half) var(--spacing-scale-2x) !important;
  font-size: var(--font-size-scale-up-01) !important;
  font-weight: var(--font-weight-semi-bold) !important;
  font-family: var(--font-family-base) !important;
  align-items: center;
  justify-content: start;
  display: inline-flex; }
  .ui-widget.ui-button.ui-button-text,
  .ui-widget.ui-button .ui-button-text,
  .ui-button.ui-button-text,
  .ui-button .ui-button-text,
  .ui-button.br-button.ui-button-text,
  .ui-button.br-button .ui-button-text {
    font-family: var(--font-family-base) !important;
    line-height: var(--font-line-height-medium) !important;
    padding: var(--spacing-scale-half) var(--spacing-scale-2x) var(--spacing-scale-half) !important; }
  .ui-widget.ui-button.ui-button-text-icon-left .ui-button-text,
  .ui-button.ui-button-text-icon-left .ui-button-text,
  .ui-button.br-button.ui-button-text-icon-left .ui-button-text {
    padding: var(--spacing-scale-base) var(--spacing-scale-half) var(--spacing-scale-half) var(--spacing-scale-base) !important;
    font-size: var(--font-size-scale-up-01) !important;
    font-weight: var(--font-weight-semi-bold) !important;
    font-family: var(--font-family-base) !important;
    line-height: var(--font-line-height-medium) !important; }
  .ui-widget.ui-button.ui-button-text-icon-left .ui-button-icon-left,
  .ui-button.ui-button-text-icon-left .ui-button-icon-left,
  .ui-button.br-button.ui-button-text-icon-left .ui-button-icon-left {
    padding: 0 !important;
    position: unset;
    top: 0;
    margin-top: var(--spacing-scale-half);
    height: unset; }
  .ui-widget.ui-button.ui-button-icon-only .ui-button-icon-left,
  .ui-button.ui-button-icon-only .ui-button-icon-left,
  .ui-button.br-button.ui-button-icon-only .ui-button-icon-left {
    padding: 0 !important;
    position: unset;
    top: unset;
    margin-top: unset;
    margin-left: unset;
    height: unset;
    left: unset;
    width: 100%; }

.ui-widget.ui-button,
.ui-button {
  --button-border: var(--interactive);
  --button-background: var(--color-secondary-01);
  --button-color: var(--interactive);
  --hover: var(--surface-opacity-xs, 0.16);
  border: 1px solid var(--button-border) !important;
  color: var(--button-color) !important;
  background: var(--button-background) !important;
  transition: background-color .2s; }
  .ui-widget.ui-button:not(:disabled):hover, .ui-widget.ui-button.br-button:not(:disabled):hover,
  .ui-button:not(:disabled):hover,
  .ui-button.br-button:not(:disabled):hover {
    background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover))) !important; }
    .ui-widget.ui-button:not(:disabled):hover.ui-button-primary, .ui-widget.ui-button:not(:disabled):hover.ui-button-success, .ui-widget.ui-button.br-button:not(:disabled):hover.ui-button-primary, .ui-widget.ui-button.br-button:not(:disabled):hover.ui-button-success,
    .ui-button:not(:disabled):hover.ui-button-primary,
    .ui-button:not(:disabled):hover.ui-button-success,
    .ui-button.br-button:not(:disabled):hover.ui-button-primary,
    .ui-button.br-button:not(:disabled):hover.ui-button-success {
      background-color: var(--button-primary-hover-color) !important; }

.ui-button.ui-button-secondary.ui-state-default,
.ui-splitbutton.ui-button-secondary .ui-button.ui-state-default {
  --buuton-border: var(--interactive);
  --button-background: transparent;
  --button-color: var(--interactive); }

.ui-button.primary.ui-state-default,
.ui-button.ui-button-primary.ui-state-default,
.ui-splitbutton.ui-button-primary .ui-button.ui-state-default,
.ui-button.ui-button-success.ui-state-default,
.ui-splitbutton.ui-button-success .ui-button.ui-state-default {
  --button-border: var(--color-dark-rgb);
  --button-background: var(--interactive);
  --button-color: var(--color-dark); }

.ui-button.ui-button-icon-only,
.ui-button.ui-button-icon-only.br-button {
  width: 32px;
  height: 32px;
  border-color: transparent !important;
  background-color: transparent;
  padding: 0 !important;
  display: inline-flex; }
  .ui-button.ui-button-icon-only .ui-button-text,
  .ui-button.ui-button-icon-only.br-button .ui-button-text {
    display: none; }
  .ui-button.ui-button-icon-only .ui-button-icon
.ui-button-icon-left,
  .ui-button.ui-button-icon-only.br-button .ui-button-icon
.ui-button-icon-left {
    position: relative !important;
    padding: 0 !important;
    margin-left: 0 !important;
    color: var(--interactive); }

.ui-orderlist .ui-orderlist-controls .ui-button {
  border-color: var(--color-dark-rgb) !important;
  background-color: var(--interactive) !important;
  color: var(--color-dark) !important; }

.ui-button:focus-visible {
  outline-color: var(--focus) !important;
  outline-offset: var(--focus-offset) !important;
  outline-style: var(--focus-style) !important;
  outline-width: var(--focus-width) !important; }

.ui-button:not(:disabled):active::after,
.ui-button:not(:disabled).pressed::after {
  background: currentColor;
  bottom: 0;
  border-radius: 100em;
  content: "";
  left: 0;
  opacity: var(--pressed);
  position: absolute;
  right: 0; }

.br-card,
.divCard {
  --interactive-rgb: var(--rgb-secondary-08);
  --card-background: var(--color-secondary-01);
  --card-back-background: var(--color-secondary-07);
  --card-margin: var(--spacing-scale-2x);
  --card-padding: var(--spacing-scale-2x);
  --card-shadow: var(--surface-shadow-sm);
  background: var(--card-background);
  box-shadow: var(--card-shadow) !important;
  color: var(--card-color);
  margin-bottom: var(--card-margin); }

.br-card,
.barraFiltro {
  border-radius: 15px !important; }

.img-logo {
  max-width: 200px;
  width: auto;
  height: auto; }

body {
  background: var(--bg-color);
  color: var(--text-color);
  font-family: var(--font-family-base);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height); }

.ui-widget {
  color: var(--text-color);
  font-family: var(--font-family-base);
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight);
  line-height: var(--font-line-height-medium); }

.anuncio .titulo,
.anuncio-detalhe .titulo {
  color: var(--text-color) !important;
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-bold);
  display: block;
  overflow: visible;
  text-transform: uppercase;
  white-space: initial;
  width: 100%; }
  .anuncio .titulo a,
  .anuncio-detalhe .titulo a {
    margin: var(--spacing-scale-base) var(--spacing-scale-half) var(--spacing-scale-2x);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    width: 98%;
    text-decoration: none; }

.anuncio {
  padding: var(--spacing-scale-2x) var(--spacing-scale-2x);
  border-radius: 15px; }
  .anuncio .foto img {
    z-index: 0; }
  .anuncio .ribbon {
    z-index: 1; }
  .anuncio .info,
  .anuncio .numero {
    font-size: var(--font-size-scale-down-02); }
  .anuncio .ui-fieldset {
    margin-top: 0; }
    .anuncio .ui-fieldset .ui-fieldset-legend {
      margin: 0; }

.anuncio-list {
  height: calc(100vh - 315px);
  overflow-y: auto; }

.anuncio-filters {
  min-height: calc(100vh - 315px);
  height: fit-content; }

@media (max-width: 576px) {
  .anuncio-list,
  .anuncio-filters {
    height: fit-content; } }

.ui-breadcrumb {
  padding-left: var(--spacing-scale-2x) !important; }
  .ui-breadcrumb .pi {
    color: var(--color-secondary-04) !important; }
  .ui-breadcrumb span,
  .ui-breadcrumb .pi-home {
    color: var(--interactive) !important; }
    .ui-breadcrumb span:hover,
    .ui-breadcrumb .pi-home:hover {
      color: var(--interactive) !important; }
  .ui-breadcrumb .ui-menuitem-text {
    line-height: var(--spacing-scale-4x); }

.ui-menuitem-link .ui-menuitem-icon,
.ui-menuitem-link .ui-menuitem-text,
.ui-menuitem-link:hover .ui-menuitem-icon,
.ui-menuitem-link:hover .ui-menuitem-text {
  color: var(--interactive) !important; }

.breadcrumb-reuse[_ngcontent-c3] {
  position: relative !important;
  width: 100%;
  top: 0 !important;
  z-index: 0 !important; }

.ui-breadcrumb .ui-menuitem-icon {
  display: none !important; }

.divIndex,
.divIndex .container {
  background-color: var(--color-secondary-01) !important;
  color: var(--text-color) !important; }

carousel slide div.item {
  min-height: 400px !important;
  max-height: fit-content !important; }

footer {
  line-height: initial !important; }

.ui-messages-info {
  background-color: var(--color-primary-default) !important;
  border-color: transparent !important;
  padding: var(--spacing-scale-half) 0;
  color: var(--color-secondary-01) !important; }

.anuncioGoverno,
.anuncioPrivado {
  border-top: 0 !important;
  border-bottom: 0 !important;
  border-radius: inherit; }

.ui-panel-titlebar, .ui-dialog-titlebar {
  background-color: var(--color-primary-default) !important;
  color: var(--color-dark) !important; }
  .ui-panel-titlebar .titulo, .ui-dialog-titlebar .titulo {
    color: var(--color-dark) !important; }

.ui-dropdown {
  background: var(--color-secondary-01) !important;
  border: 1px solid var(--color-secondary-06) !important;
  border-radius: var(--surface-rounder-sm) !important;
  padding: var(--spacing-scale-base) var(--spacing-scale-2x) !important;
  width: 100% !important; }
  .ui-dropdown.ui-dropdown-open, .ui-dropdown.ui-state-focus, .ui-dropdown:focus {
    border-color: var(--focus) !important;
    box-shadow: 0 0 0 var(--surface-width-md) var(--focus) !important;
    outline: none; }
  .ui-dropdown:not(:disabled):hover {
    --interactive-rgb: var(--rgb-secondary-08);
    background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover))) !important; }
    .ui-dropdown:not(:disabled):hover .ui-inputtext,
    .ui-dropdown:not(:disabled):hover .ui-inputtext:hover,
    .ui-dropdown:not(:disabled):hover .ui-inputtext:focus {
      background-color: transparent !important;
      background-image: none !important; }
  .ui-dropdown .ui-dropdown-panel {
    margin-top: 1px; }
  .ui-dropdown .ui-dropdown-label,
  .ui-dropdown .ui-dropdown-trigger,
  .ui-dropdown .ui-inputtext,
  .ui-dropdown label.ui-dropdown-label {
    background-color: transparent !important;
    border: 0 !important;
    padding: 0 var(--spacing-scale-2x) 0 0 !important; }
  .ui-dropdown .ui-dropdown-label {
    padding-right: var(--spacing-scale-3x) !important; }
  .ui-dropdown .ui-dropdown-panel .ui-dropdown-filter-container {
    border-bottom: 1px solid var(--gray-10); }
    .ui-dropdown .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter.ui-inputtext {
      padding: var(--spacing-scale-half) var(--spacing-scale-3x) var(--spacing-scale-half) var(--spacing-scale-base) !important;
      border: 0 !important; }
      .ui-dropdown .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter.ui-inputtext:focus {
        box-shadow: none !important; }
    .ui-dropdown .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter-icon {
      cursor: default; }
  .ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon.ui-clickable.pi.pi-caret-down {
    font-size: var(--font-size-scale-down-01) !important;
    color: var(--interactive);
    border-radius: 50% !important;
    padding: 8px;
    top: 10px;
    right: 0px !important; }
    .ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon.ui-clickable.pi.pi-caret-down:hover {
      background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover))) !important; }
    .ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon.ui-clickable.pi.pi-caret-down:before {
      content: "\e902"; }
  .ui-dropdown.ui-dropdown-open .ui-dropdown-trigger .ui-dropdown-trigger-icon.pi-caret-down:before {
    content: "\e903"; }
  .ui-dropdown .ui-dropdown-panel .ui-dropdown-list {
    padding: 0;
    border: 0 none; }
  .ui-dropdown .ui-dropdown-items {
    padding: 0; }
    .ui-dropdown .ui-dropdown-items .ui-dropdown-item {
      padding: var(--spacing-scale-base) var(--spacing-scale-2x);
      margin: 0 !important;
      border-bottom: 1px solid var(--color-secondary-01) !important;
      border-radius: 0 !important; }
      .ui-dropdown .ui-dropdown-items .ui-dropdown-item.ui-state-highlight {
        background-color: var(--selected) !important; }

.ui-fluid .ui-multiselect,
.ui-fluid .ui-multiselect:not(.ui-state-disabled):hover {
  background: var(--color-secondary-01) !important;
  border: 1px solid var(--color-secondary-06) !important;
  border-radius: var(--surface-rounder-sm) !important;
  color: var(--color-secondary-08) !important;
  display: block;
  font-size: var(--font-size-scale-up-01) !important;
  font-weight: var(--font-weight-medium) !important;
  height: auto !important;
  margin-bottom: 0 !important;
  margin-top: var(--spacing-scale-half) !important;
  padding: var(--spacing-scale-base) var(--spacing-scale-2x) !important;
  width: 100%; }
  .ui-fluid .ui-multiselect.ui-state-focus, .ui-fluid .ui-multiselect:focus,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover.ui-state-focus,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover:focus {
    border-color: var(--focus) !important;
    box-shadow: 0 0 0 var(--surface-width-md) var(--focus) !important;
    outline: none; }
  .ui-fluid .ui-multiselect.ui-state-hover, .ui-fluid .ui-multiselect:not(:disabled):hover,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover.ui-state-hover,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover:not(:disabled):hover {
    --interactive-rgb: var(--rgb-secondary-08);
    background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover))) !important; }
  .ui-fluid .ui-multiselect .ui-multiselect-panel,
  .ui-fluid .ui-multiselect .ui-multiselect-panel .ui-multiselect-items-wrapper,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-panel,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-panel .ui-multiselect-items-wrapper {
    padding: 0; }
  .ui-fluid .ui-multiselect .ui-multiselect-panel,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-panel {
    margin-top: 1px; }
  .ui-fluid .ui-multiselect .ui-widget-header,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-widget-header {
    padding: var(--spacing-scale-base) var(--spacing-scale-2x);
    margin-bottom: 0;
    background: var(--color-bg) !important;
    border-radius: 0; }
  .ui-fluid .ui-multiselect .ui-multiselect-label,
  .ui-fluid .ui-multiselect .ui-multiselect-trigger,
  .ui-fluid .ui-multiselect .ui-inputtext,
  .ui-fluid .ui-multiselect .ui-inputtext:hover,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-label,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-trigger,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-inputtext,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-inputtext:hover {
    background-color: transparent !important;
    background-image: none !important; }
  .ui-fluid .ui-multiselect .ui-chkbox-box,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-chkbox-box {
    margin-right: var(--spacing-scale-base); }
  .ui-fluid .ui-multiselect .ui-multiselect-item,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-item {
    padding: var(--spacing-scale-base) var(--spacing-scale-2x);
    margin: 0 !important;
    border-bottom: 0px solid var(--color-bg) !important;
    border-radius: 0 !important; }
  .ui-fluid .ui-multiselect .ui-multiselect-header .ui-multiselect-filter-container,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-header .ui-multiselect-filter-container {
    width: calc(100% - 38px); }
  .ui-fluid .ui-multiselect .ui-inputtext,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-inputtext {
    padding-left: var(--spacing-scale-3x) !important; }
    .ui-fluid .ui-multiselect .ui-inputtext.ui-state-focus, .ui-fluid .ui-multiselect .ui-inputtext:focus,
    .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-inputtext.ui-state-focus,
    .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-inputtext:focus {
      box-shadow: none !important; }
  .ui-fluid .ui-multiselect .ui-inputtext,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-inputtext {
    color: var(--color-text) !important;
    line-height: 100%;
    border: 0 !important;
    height: fit-content;
    padding: 0 var(--spacing-scale-3x) !important; }
  .ui-fluid .ui-multiselect .ui-multiselect-filter-icon,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-filter-icon {
    color: var(--color-text) !important; }
  .ui-fluid .ui-multiselect .ui-multiselect-filter-icon,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-filter-icon {
    cursor: default;
    top: 0.18em; }
  .ui-fluid .ui-multiselect .ui-multiselect-close,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-close {
    border-radius: 50%; }
    .ui-fluid .ui-multiselect .ui-multiselect-close .pi,
    .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-close .pi {
      line-height: 28px;
      color: var(--interactive) !important;
      padding: 0 6px; }
  .ui-fluid .ui-multiselect .ui-multiselect-label,
  .ui-fluid .ui-multiselect .ui-multiselect-label-container,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-label,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-label-container {
    color: var(--text-color);
    font-family: var(--font-family-base) !important;
    font-size: var(--font-size);
    font-weight: var(--font-weight-medium);
    padding: 0 !important;
    padding-right: var(--spacing-scale-2x) !important;
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .ui-fluid .ui-multiselect .ui-multiselect-trigger .ui-multiselect-trigger-icon,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-trigger .ui-multiselect-trigger-icon {
    font-size: var(--font-size-scale-down-01);
    color: var(--interactive);
    border-radius: 50% !important;
    padding: 8px;
    top: 10px;
    right: 0px; }
    .ui-fluid .ui-multiselect .ui-multiselect-trigger .ui-multiselect-trigger-icon:hover,
    .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-trigger .ui-multiselect-trigger-icon:hover {
      background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover))) !important; }
    .ui-fluid .ui-multiselect .ui-multiselect-trigger .ui-multiselect-trigger-icon.pi-caret-down:before,
    .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-trigger .ui-multiselect-trigger-icon.pi-caret-down:before {
      content: "\e902"; }
  .ui-fluid .ui-multiselect.ui-multiselect-open .ui-multiselect-trigger .ui-multiselect-trigger-icon.pi-caret-down:before,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover.ui-multiselect-open .ui-multiselect-trigger .ui-multiselect-trigger-icon.pi-caret-down:before {
    content: "\e903"; }
  .ui-fluid .ui-multiselect .ui-state-highlight,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-state-highlight {
    border-color: transparent;
    background-color: var(--selected);
    color: var(--color-dark); }
  .ui-fluid .ui-multiselect .ui-chkbox-box.ui-state-active,
  .ui-fluid .ui-multiselect .ui-radiobutton-box.ui-state-active,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-chkbox-box.ui-state-active,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-radiobutton-box.ui-state-active {
    border: 1px solid var(--color-secondary-04);
    background: var(--color-secondary-01) !important;
    color: var(--selected); }

.ui-message {
  --message-background: var(--gray-80);
  --message-color-icon: var(--gray-80);
  --feedback-background: var(--gray-80);
  --message-text: var(--color);
  --message-opacity: var(--surface-opacity-xs);
  --message-font-size: var(--font-size-scale-up-01);
  --message-padding: var(--spacing-scale-3x) var(--spacing-scale-base) var(--spacing-scale-3x) var(--spacing-scale-2x);
  --message-margin: var(--spacing-scale-2x);
  --message-icon: var(--icon-size-lg);
  --feedback-padding: var(--spacing-scale-half);
  --feedback-font-weight: var(--font-weight-medium);
  --message-opacity: 1;
  align-items: center;
  background: var(--feedback-background);
  border-radius: 0;
  color: var(--message-text);
  display: inline-flex;
  font-style: italic;
  font-weight: var(--feedback-font-weight);
  margin-bottom: 0;
  padding: var(--feedback-padding); }
  .ui-message.ui-messages-info {
    --message-background: var(--info-alternative);
    --feedback-background: var(--info);
    --message-color-icon: var(--info); }
  .ui-message.ui-messages-warn {
    --message-background: var(--warning-alternative);
    --feedback-background: var(--warning); }
  .ui-message .ui-message-icon {
    margin: 0 var(--spacing-scale-half); }

.ui-inputtext,
input[_ngcontent-c7],
.ui-inputtext[_ngcontent-c7],
.ui-widget input,
form .ui-inputtext,
form .ui-widget input,
.ui-fluid .ui-inputtext,
.ui-fluid input[_ngcontent-c7],
.ui-fluid .ui-inputtext[_ngcontent-c7] {
  background-color: var(--color-secondary-01) !important;
  border: 1px solid var(--color-secondary-06) !important;
  border-radius: var(--surface-rounder-sm) !important;
  color: var(--color-secondary-08) !important;
  font-size: var(--font-size-scale-base) !important;
  font-weight: var(--font-weight-medium) !important;
  font-family: var(--font-family-base) !important;
  height: auto !important;
  padding: var(--spacing-scale-base) var(--spacing-scale-2x) !important;
  width: 100%; }
  .ui-inputtext.ui-state-focus, .ui-inputtext:focus,
  input[_ngcontent-c7].ui-state-focus,
  input[_ngcontent-c7]:focus,
  .ui-inputtext[_ngcontent-c7].ui-state-focus,
  .ui-inputtext[_ngcontent-c7]:focus,
  .ui-widget input.ui-state-focus,
  .ui-widget input:focus,
  form .ui-inputtext.ui-state-focus,
  form .ui-inputtext:focus,
  form .ui-widget input.ui-state-focus,
  form .ui-widget input:focus,
  .ui-fluid .ui-inputtext.ui-state-focus,
  .ui-fluid .ui-inputtext:focus,
  .ui-fluid input[_ngcontent-c7].ui-state-focus,
  .ui-fluid input[_ngcontent-c7]:focus,
  .ui-fluid .ui-inputtext[_ngcontent-c7].ui-state-focus,
  .ui-fluid .ui-inputtext[_ngcontent-c7]:focus {
    border-color: var(--focus) !important;
    box-shadow: 0 0 0 var(--surface-width-md) var(--focus) !important;
    outline: none; }
  .ui-inputtext.ui-state-hover, .ui-inputtext:not(:disabled):hover,
  input[_ngcontent-c7].ui-state-hover,
  input[_ngcontent-c7]:not(:disabled):hover,
  .ui-inputtext[_ngcontent-c7].ui-state-hover,
  .ui-inputtext[_ngcontent-c7]:not(:disabled):hover,
  .ui-widget input.ui-state-hover,
  .ui-widget input:not(:disabled):hover,
  form .ui-inputtext.ui-state-hover,
  form .ui-inputtext:not(:disabled):hover,
  form .ui-widget input.ui-state-hover,
  form .ui-widget input:not(:disabled):hover,
  .ui-fluid .ui-inputtext.ui-state-hover,
  .ui-fluid .ui-inputtext:not(:disabled):hover,
  .ui-fluid input[_ngcontent-c7].ui-state-hover,
  .ui-fluid input[_ngcontent-c7]:not(:disabled):hover,
  .ui-fluid .ui-inputtext[_ngcontent-c7].ui-state-hover,
  .ui-fluid .ui-inputtext[_ngcontent-c7]:not(:disabled):hover {
    --interactive-rgb: var(--rgb-secondary-08);
    background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover))) !important; }

.ui-paginator {
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .ui-paginator .ui-paginator-pages {
    height: auto !important;
    line-height: auto !important; }
  .ui-paginator .ui-paginator-page,
  .ui-paginator .ui-paginator-element {
    align-items: center;
    border: 1px solid transparent !important;
    border-radius: 50%;
    border-color: transparent !important;
    color: var(--interactive);
    display: inline-flex;
    font-weight: var(--font-weight);
    justify-content: center;
    margin: 0 2px;
    padding: 0 var(--spacing-scale-base);
    white-space: nowrap;
    height: 28px !important;
    width: auto !important;
    min-width: 28px !important; }
    .ui-paginator .ui-paginator-page:not(.ui-state-disabled):hover,
    .ui-paginator .ui-paginator-element:not(.ui-state-disabled):hover {
      background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover))) !important; }
    .ui-paginator .ui-paginator-page.ui-state-active,
    .ui-paginator .ui-paginator-element.ui-state-active {
      background: var(--active) !important;
      color: var(--bg-color) !important;
      border-color: transparent !important; }

.barraListagem {
  margin-top: 0; }
  .barraListagem .barraFiltro {
    width: 30%;
    min-height: calc(100vh - 274px);
    height: fit-content; }
    .barraListagem .barraFiltro .barraFiltroInterna {
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      padding: 10px;
      display: -ms-grid;
      display: grid; }
      .barraListagem .barraFiltro .barraFiltroInterna .ui-fluid .ui-multiselect .ui-multiselect-label,
      .barraListagem .barraFiltro .barraFiltroInterna .ui-fluid .ui-multiselect .ui-multiselect-label-container,
      .barraListagem .barraFiltro .barraFiltroInterna .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-label,
      .barraListagem .barraFiltro .barraFiltroInterna .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-label-container {
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px; }
      .barraListagem .barraFiltro .barraFiltroInterna div.text-center:last-child {
        display: flex;
        justify-content: space-between; }
  .barraListagem .barraItens {
    width: 70%; }
    .barraListagem .barraItens .barraTotalOrdem + div {
      height: calc(100vh - 320px);
      overflow-y: auto; }

@media (max-width: 992px) {
  .barraListagem {
    flex-wrap: wrap; }
    .barraListagem .barraFiltro {
      margin: 0 var(--spacing-scale-2x);
      width: 100%;
      margin-bottom: var(--spacing-scale-3x); }
      .barraListagem .barraFiltro .barraFiltroInterna .ui-fluid .ui-multiselect .ui-multiselect-label,
      .barraListagem .barraFiltro .barraFiltroInterna .ui-fluid .ui-multiselect .ui-multiselect-label-container,
      .barraListagem .barraFiltro .barraFiltroInterna .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-label,
      .barraListagem .barraFiltro .barraFiltroInterna .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-label-container {
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 70vw; }
    .barraListagem .barraItens {
      margin-left: 0;
      width: 100%;
      height: fit-content; } }

.ui-inputwrapper-focus .ui-inputtext .ui-inputwrapper-focus .ui-inputtext:focus {
  border-color: var(--focus) !important;
  box-shadow: 0 0 0 var(--surface-width-md) var(--focus) !important;
  outline: none; }

.ui-inputwrapper-focus .ui-inputtext.ui-state-hover, .ui-inputwrapper-focus .ui-inputtext:not(:disabled):hover {
  --interactive-rgb: var(--rgb-secondary-08);
  background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover))) !important; }

.ui-fluid .ui-calendar.ui-calendar-w-btn .ui-inputtext {
  width: 100% !important;
  padding-right: var(--spacing-scale-4x) !important; }

.ui-calendar .ui-calendar-button {
  position: absolute;
  border-color: transparent !important;
  background-color: transparent !important;
  right: 0;
  top: 0;
  height: 100%; }
  .ui-calendar .ui-calendar-button .ui-button-icon-left {
    font-size: var(--icon-size-lg); }

.ui-datepicker {
  margin-top: 2px; }

.ui-datepicker.ui-widget .footer-datepicker {
  display: none; }

.ui-datepicker .ui-datepicker-header {
  background: var(--bg-color) !important;
  color: var(--text-color) !important; }

.ui-datepicker .ui-datepicker-title {
  color: var(--interactive) !important;
  text-transform: capitalize;
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-semi-bold); }

.ui-datepicker .ui-datepicker-header {
  margin: 0 var(--spacing-scale-3x);
  border-bottom: 0; }
  .ui-datepicker .ui-datepicker-header a {
    border-radius: 50%;
    line-height: var(--font-size-scale-up-03);
    margin-top: 3px; }
    .ui-datepicker .ui-datepicker-header a span:before {
      color: var(--interactive) !important;
      font-size: var(--font-size-scale-base); }

.ui-datepicker.ui-widget .ui-datepicker-calendar {
  margin-bottom: var(--spacing-scale-2x);
  border: 0 !important; }

.ui-datepicker.ui-widget .ui-datepicker-calendar thead th {
  background-color: transparent;
  text-align: center;
  font-weight: var(--font-weight-medium); }

.ui-datepicker.ui-widget .ui-datepicker-calendar td {
  border-bottom: 0;
  text-align: center;
  text-align: -webkit-center; }
  .ui-datepicker.ui-widget .ui-datepicker-calendar td a {
    border-radius: 50%;
    color: var(--interactive) !important;
    padding: 3px var(--spacing-scale-half) !important;
    margin: 2px var(--spacing-scale-half) !important;
    font-weight: --font-weight-semi-bold !important;
    width: 2em !important;
    height: 2em !important; }
    .ui-datepicker.ui-widget .ui-datepicker-calendar td a.ui-state-highlight {
      background-color: var(--color-support-11) !important;
      border-color: transparent;
      color: var(--color-support-03) !important; }
    .ui-datepicker.ui-widget .ui-datepicker-calendar td a.ui-state-active {
      background-color: var(--selected) !important;
      color: var(--color-secondary-01) !important; }
  .ui-datepicker.ui-widget .ui-datepicker-calendar td.ui-state-disabled a {
    font-weight: var(--font-weight-medium); }
    .ui-datepicker.ui-widget .ui-datepicker-calendar td.ui-state-disabled a:hover {
      background-image: none !important; }

.ui-widget-content .ui-button.ui-spinner-button {
  cursor: default;
  display: block;
  height: 50% !important;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  vertical-align: middle;
  width: 1.5em !important;
  border: 0 !important;
  background-color: transparent !important; }

.ui-fieldset,
.ui-fieldset .ui-fieldset-legend {
  background-color: var(--bg-color) !important;
  color: var(--text-color);
  padding-left: var(--spacing-scale-base) !important; }
  .ui-fieldset .ui-fieldset-legend-text,
  .ui-fieldset .ui-fieldset-legend .ui-fieldset-legend-text {
    border: transparent;
    background-color: var(--bg-color);
    color: var(--interactive); }
  .ui-fieldset.ui-state-default,
  .ui-fieldset .ui-fieldset-legend.ui-state-default {
    border: unset; }

.ui-fieldset .ui-fieldset-content-wrapper .ui-fieldset-content {
  padding: 0 var(--spacing-scale-base); }

.ui-g .governo > .ui-fieldset,
.ui-g .privado > .ui-fieldset {
  background-color: var(--bg-color) !important;
  padding: var(--spacing-scale-2x) var(--spacing-scale-base) !important; }

.governo > .ui-fieldset > .ui-fieldset-legend,
.privado > .ui-fieldset > .ui-fieldset-legend {
  background-color: var(--bg-color) !important; }

.ui-accordion .ui-accordion-header {
  border: 0;
  border-radius: 0 !important; }
  .ui-accordion .ui-accordion-header.ui-state-active {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top-color: transparent !important;
    border-top-width: 0 !important;
    border-top-style: solid !important;
    border-bottom-color: transparent !important;
    border-bottom-width: 0 !important;
    border-bottom-style: solid !important; }
  .ui-accordion .ui-accordion-header a {
    display: flex;
    flex-direction: row-reverse;
    font-size: var(--font-size-scale-base);
    font-weight: var(--font-weight-regular);
    text-transform: uppercase;
    align-items: center;
    padding: var(--spacing-scale-base) var(--spacing-scale-2x);
    text-decoration: none !important; }
    .ui-accordion .ui-accordion-header a span {
      font-weight: var(--font-weight-bold);
      color: var(--interactive);
      padding-right: var(--spacing-scale-base); }
  .ui-accordion .ui-accordion-header .ui-accordion-toggle-icon {
    margin-left: auto;
    margin-right: var(--spacing-scale-base);
    padding-left: var(--spacing-scale-base); }
    .ui-accordion .ui-accordion-header .ui-accordion-toggle-icon.pi-caret-down:before {
      content: "\e903"; }
    .ui-accordion .ui-accordion-header .ui-accordion-toggle-icon.pi-caret-right:before {
      content: "\e902"; }
  .ui-accordion .ui-accordion-header > a > span,
  .ui-accordion .ui-accordion-header .ui-accordion .ui-accordion-header > span > span {
    color: var(--interactive); }

.ui-accordion .ui-accordion-content {
  padding: 1em;
  border-top: 0;
  overflow: visible;
  border-radius: 0 !important;
  border-bottom-color: var(--color-secondary-04);
  zoom: 1; }

.ui-steps {
  display: flex; }
  .ui-steps ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%; }
  .ui-steps li.ui-steps-item {
    width: 100% !important;
    box-sizing: border-box;
    border: 0;
    position: relative; }
    .ui-steps li.ui-steps-item .ui-menuitem-link {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .ui-steps li.ui-steps-item .ui-menuitem-link .ui-steps-number {
        display: block;
        position: relative;
        font-size: var(--font-size-scale-up-02);
        line-height: 25px;
        width: 32px;
        height: 32px;
        background-color: var(--bg-color);
        border: 2px solid var(--interactive);
        border-radius: 50%;
        color: var(--interactive);
        font-weight: var(--font-weight-bold); }
      .ui-steps li.ui-steps-item .ui-menuitem-link .ui-steps-title {
        color: var(--interactive);
        margin-top: var(--spacing-scale-2x);
        display: block;
        font-weight: var(--font-weight-regular);
        white-space: nowrap; }
    .ui-steps li.ui-steps-item.ui-state-highlight {
      background-color: transparent !important; }
      .ui-steps li.ui-steps-item.ui-state-highlight .ui-menuitem-link {
        cursor: pointer !important;
        text-decoration: none; }
        .ui-steps li.ui-steps-item.ui-state-highlight .ui-menuitem-link .ui-steps-title {
          color: var(--interactive); }
        .ui-steps li.ui-steps-item.ui-state-highlight .ui-menuitem-link .ui-steps-number {
          color: var(--color-dark);
          background-color: var(--interactive); }
    .ui-steps li.ui-steps-item.ui-state-complete .ui-steps-number::before {
      background-color: var(--color-success);
      border-radius: 50%;
      color: var(--color-dark);
      content: "\f00c";
      display: block;
      font-family: "FontAwesome", sans-serif;
      font-size: var(--icon-size-sm);
      width: 18px;
      height: 18px;
      line-height: 18px;
      position: absolute;
      top: -5px;
      right: -8px; }
    .ui-steps li.ui-steps-item.ui-state-disabled .ui-menuitem-link {
      cursor: default;
      background-image: none !important;
      color: inherit !important; }
    .ui-steps li.ui-steps-item::before {
      display: block;
      content: "";
      width: calc(100% - 32px);
      height: 1px;
      background-color: var(--gray-10);
      position: absolute;
      top: 30px;
      left: calc(50% + 16px); }
    .ui-steps li.ui-steps-item:last-child::before {
      display: none; }

@media (max-width: 576px) {
  .ui-steps li.ui-steps-item .ui-menuitem-link .ui-steps-title {
    display: none; }
  .ui-steps li.ui-steps-item::before {
    top: 22px; } }

.anuncio-detalhe .galeria {
  display: flex;
  min-height: 400px; }
  .anuncio-detalhe .galeria .img {
    display: flex;
    position: absolute;
    margin-left: var(--spacing-scale-2x);
    margin-top: var(--spacing-scale-2x);
    width: calc(100% - var(--spacing-scale-4x)) !important;
    height: calc(100% - var(--spacing-scale-4x)) !important; }
    .anuncio-detalhe .galeria .img .ngx-gallery-image {
      width: calc(100% - 64px);
      margin-left: 32px; }
  .anuncio-detalhe .galeria .ngx-gallery-arrow-left > .ngx-gallery-icon,
  .anuncio-detalhe .galeria .ngx-gallery-arrow-right > .ngx-gallery-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    color: var(--interactive) !important;
    background-color: var(--bg-color); }
    .anuncio-detalhe .galeria .ngx-gallery-arrow-left > .ngx-gallery-icon .ngx-gallery-icon-content::before,
    .anuncio-detalhe .galeria .ngx-gallery-arrow-right > .ngx-gallery-icon .ngx-gallery-icon-content::before {
      display: inline-flex;
      font-family: "Font Awesome 5 Free", sans-serif;
      font-size: var(--switch-icon-size);
      color: var(--interactive) !important; }
  .anuncio-detalhe .galeria .ngx-gallery-arrow-left > .ngx-gallery-icon {
    left: 0px !important; }
    .anuncio-detalhe .galeria .ngx-gallery-arrow-left > .ngx-gallery-icon .ngx-gallery-icon-content::before {
      content: "\f053"; }
  .anuncio-detalhe .galeria .ngx-gallery-arrow-right > .ngx-gallery-icon {
    right: 0px !important; }
    .anuncio-detalhe .galeria .ngx-gallery-arrow-right > .ngx-gallery-icon .ngx-gallery-icon-content::before {
      content: "\f054"; }

.erro-form {
  color: var(--color-danger) !important; }

label,
.label,
.subtitulo {
  font-family: var(--font-family-base);
  font-size: var(--font-size);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--font-line-height-medium); }

.ui-panel.ui-widget {
  padding: 0;
  margin-bottom: var(--spacing-scale-3x); }

.ui-table .ui-table-tfoot > tr > td,
.ui-table .ui-table-tbody > tr > td,
.ui-table .ui-table-tbody > tr > td {
  border: 0;
  border-bottom: 1px solid var(--color-secondary-04);
  font-size: var(--font-size-scale-down-01);
  padding: var(--spacing-scale-2x); }

.ui-table .ui-table-thead > tr > th {
  border: 0;
  background-color: var(--color-secondary-03);
  font-size: var(--font-size-scale-down-01);
  padding: var(--spacing-scale-2x); }

.ui-table .ui-sortable-column.ui-state-highlight {
  background-color: var(--interactive) !important;
  color: var(--color-dark); }

.panel-publicacao .ui-panel .ui-panel-content {
  display: flex;
  flex-wrap: wrap; }

.ui-panel.ui-widget {
  padding: 0 !important;
  border: 0 !important;
  margin-bottom: var(--spacing-scale-3x);
  background: var(--color-secondary-01);
  box-shadow: var(--surface-shadow-sm) !important;
  border-radius: 15px !important;
  color: var(--card-color);
  overflow: hidden; }

.ui-panel .ui-panel-content {
  border: 0;
  background: 0;
  padding: 0; }
  .ui-panel .ui-panel-content .ui-g-1, .ui-panel .ui-panel-content .ui-g-2, .ui-panel .ui-panel-content .ui-g-3, .ui-panel .ui-panel-content .ui-g-4, .ui-panel .ui-panel-content .ui-g-5, .ui-panel .ui-panel-content .ui-g-6, .ui-panel .ui-panel-content .ui-g-7, .ui-panel .ui-panel-content .ui-g-8, .ui-panel .ui-panel-content .ui-g-9, .ui-panel .ui-panel-content .ui-g-10, .ui-panel .ui-panel-content .ui-g-11, .ui-panel .ui-panel-content .ui-g-12 {
    padding: 0; }
  .ui-panel .ui-panel-content .ui-table table {
    border-bottom: 0 !important; }
  .ui-panel .ui-panel-content form {
    padding: 0 var(--spacing-scale-2x) var(--spacing-scale-base) !important; }
    .ui-panel .ui-panel-content form .ui-g-1, .ui-panel .ui-panel-content form .ui-g-2, .ui-panel .ui-panel-content form .ui-g-3, .ui-panel .ui-panel-content form .ui-g-4, .ui-panel .ui-panel-content form .ui-g-5, .ui-panel .ui-panel-content form .ui-g-6, .ui-panel .ui-panel-content form .ui-g-7, .ui-panel .ui-panel-content form .ui-g-8, .ui-panel .ui-panel-content form .ui-g-9, .ui-panel .ui-panel-content form .ui-g-10, .ui-panel .ui-panel-content form .ui-g-11, .ui-panel .ui-panel-content form .ui-g-12 {
      padding: 0.5em; }

.ui-panel-titlebar, .ui-dialog-titlebar {
  background-color: transparent !important;
  color: var(--text-color) !important;
  display: block;
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-bold);
  overflow: hidden;
  padding: var(--spacing-scale-2x) !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; }

.layout-tabela {
  margin-top: 0;
  border: 0 !important; }

.login-fundo {
  background: transparent url("/assets/images/onda.png") no-repeat bottom center/100%;
  margin-left: -3%;
  width: 106%; }

@media (min-width: 1280px) {
  .login-fundo {
    margin-left: -2% !important;
    width: 104% !important; } }

p {
  line-break: anywhere;
  word-break: break-all; }

a.lostpass {
  font-size: var(--font-size-scale-down-01) !important;
  margin-top: var(--spacing-scale-base) !important; }

.br-menu a.menu-item:not(:disabled):not(:disabled):hover {
  text-decoration: none !important; }

.divMain {
  padding: var(--spacing-scale-2x) 0;
  display: flex;
  min-height: calc(100vh - 320px);
  align-items: flex-start !important;
  font-size: 15px; }

.erro-form::after {
  content: "*";
  color: var(--color-danger);
  display: inline-block;
  font-size: 26px;
  line-height: 24px;
  overflow: hidden;
  height: 16px;
  margin-left: -6px; }

/* Mensagens de validação de formularios
*/
.ui-growl {
  position: fixed;
  top: 116px !important;
  right: var(--spacing-scale-2x);
  width: fit-content;
  width: calc(100vw - var(--spacing-scale-4x));
  color: var(--text-color);
  font-family: var(--font-family-base);
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight);
  line-height: var(--line-height); }

.ui-growl-item-container {
  position: relative;
  margin: 0 0 10px 0;
  opacity: 1 !important;
  filter: none !important; }

.ui-growl-item-container.ui-state-highlight.ui-growl-message-error {
  --interactive-rgb: var(--danger-rgb);
  background-color: var(--danger-alternative);
  border-color: var(--color-danger); }
  .ui-growl-item-container.ui-state-highlight.ui-growl-message-error .ui-growl-image.pi-times:before {
    content: "\e90c";
    color: var(--color-danger); }
  .ui-growl-item-container.ui-state-highlight.ui-growl-message-error .ui-growl-icon-close:before {
    color: var(--color-danger); }

.ui-growl-item-container.ui-state-highlight.ui-growl-message-success {
  --interactive-rgb: var(--success-rgb);
  background-color: var(--success-alternative);
  border-color: var(--color-success); }
  .ui-growl-item-container.ui-state-highlight.ui-growl-message-success .ui-growl-image:before {
    color: var(--color-success); }
  .ui-growl-item-container.ui-state-highlight.ui-growl-message-success .ui-growl-icon-close:before {
    color: var(--color-success); }

.ui-growl-item-container.ui-state-highlight {
  border-radius: 0; }
  .ui-growl-item-container.ui-state-highlight .ui-growl-item {
    position: relative;
    display: flex;
    padding: 0 var(--spacing-scale-2x);
    align-items: center; }
  .ui-growl-item-container.ui-state-highlight .ui-growl-message {
    padding: var(--spacing-scale-3x) var(--spacing-scale-base) var(--spacing-scale-3x) var(--spacing-scale-2x);
    margin-left: 0;
    color: var(--color);
    display: flex; }
    .ui-growl-item-container.ui-state-highlight .ui-growl-message .ui-growl-title {
      font-weight: bold;
      padding: 0;
      display: inline-flex; }
      .ui-growl-item-container.ui-state-highlight .ui-growl-message .ui-growl-title:after {
        content: "."; }
    .ui-growl-item-container.ui-state-highlight .ui-growl-message p {
      display: inline-flex;
      padding-left: 1em; }
  .ui-growl-item-container.ui-state-highlight .ui-growl-image {
    position: unset;
    display: inline-block;
    font-size: 2em; }
  .ui-growl-item-container.ui-state-highlight .ui-growl-icon-close {
    position: absolute;
    top: var(--spacing-scale-base);
    right: var(--spacing-scale-base);
    cursor: pointer;
    border-radius: 50%;
    padding: var(--spacing-scale-half); }
    .ui-growl-item-container.ui-state-highlight .ui-growl-icon-close:hover {
      background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover))) !important; }
    .ui-growl-item-container.ui-state-highlight .ui-growl-icon-close::before {
      font-size: var(--font-size-scale-up-02); }
