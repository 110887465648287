@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Roboto';
  src: "node_modules/roboto-fontface/css/roboto/sass/roboto-fontface"; }

:root {
  --appColor: rgb(26,57,89,0.9);
  --appColorSolid: rgba(26,57,89,0.99);
  --appColorShadow: rgba(48, 76, 105, 0.2); }

* {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: Roboto;
  font-size: 14px;
  background-color: #F4F5FA; }

/** classes-propriedade **/
.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.float-right {
  float: right; }

.absolute {
  position: absolute; }

.flex {
  display: flex; }

.width100 {
  width: 100%; }

.noPadding {
  padding: 0 !important; }

.espaco-r-5 {
  margin-right: 5px !important; }

.contentBold {
  font-weight: bold; }

.font-10 {
  font-size: 10px !important; }

.font-smaller {
  font-size: smaller !important; }

.clickable {
  cursor: pointer; }

.clickable:hover {
  text-decoration: underline; }

.nowrap {
  white-space: nowrap; }

/** classes-propriedade **/
.ui-growl {
  z-index: 999999 !important; }

.barraListagem {
  margin-top: 30px;
  display: flex;
  width: 100%; }

.barraFiltroInterna {
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 #E8E9F0;
  padding: 10px;
  display: grid; }

.barraTotalOrdem {
  padding-left: 12px;
  padding-right: 5px; }

.barraOrdem {
  padding-right: 0px !important;
  text-align: right; }

.barraItens {
  width: 70%;
  margin-left: 10px; }

.ui-messages-info {
  background-color: #5a9732 !important;
  border-color: #5a9732 !important;
  color: #e6e6e5 !important; }

.ui-fluid .ui-multiselect,
.ui-fluid .ui-multiselect-label,
.ui-fluid .ui-multiselect-trigger,
.ui-fluid .ui-multiselect:not(.ui-state-disabled):hover,
.ui-fluid .ui-inputtext,
.ui-fluid input[_ngcontent-c7],
.ui-fluid .ui-inputtext[_ngcontent-c7] {
  border: none !important;
  border-radius: 0 !important;
  background: whitesmoke !important;
  color: var(--appColor) !important;
  padding: .25em !important; }
  .ui-fluid .ui-multiselect .ui-multiselect-trigger-icon,
  .ui-fluid .ui-multiselect-label .ui-multiselect-trigger-icon,
  .ui-fluid .ui-multiselect-trigger .ui-multiselect-trigger-icon,
  .ui-fluid .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-trigger-icon,
  .ui-fluid .ui-inputtext .ui-multiselect-trigger-icon,
  .ui-fluid input[_ngcontent-c7] .ui-multiselect-trigger-icon,
  .ui-fluid .ui-inputtext[_ngcontent-c7] .ui-multiselect-trigger-icon {
    font-size: 25px;
    color: #000;
    right: 9px;
    left: unset !important; }

.ui-fluid .ui-multiselect-label {
  margin-bottom: 0px !important;
  padding: 5px !important; }

/*** multiselect, dropdown ***/
.ui-multiselect .ui-multiselect-panel, .ui-dropdown .ui-dropdown-panel {
  z-index: 2 !important; }

.ui-multiselect-header {
  background: rgba(26, 57, 89, 0.9) !important; }

.ui-dropdown .ui-dropdown-label {
  text-align: left; }

/*** multiselect, dropdown ***/
.bootstrap.ng2-tag-input.ng2-tag-input--focused[_ngcontent-c9] {
  border-bottom: 2px solid var(--appColor) !important; }

.bootstrap tag {
  background-color: rgba(26, 57, 89, 0.9) !important;
  z-index: 1; }

.ui-multiselect-close .pi.pi-times {
  color: white !important; }

/** title bar **/
.ui-panel-titlebar {
  color: white !important; }

.ui-dialog-titlebar {
  color: white !important; }

.ui-dialog-titlebar-icon {
  color: white !important; }

.ui-panel-titlebar, .ui-dialog-titlebar {
  background: var(--appColor) !important; }

/** title bar **/
.ui-chkbox-label {
  display: inline; }

.ui-dialog {
  z-index: 99999 !important; }

.ngx-gallery-arrow-wrapper {
  z-index: 1000 !important; }

.ui-paginator.ui-paginator {
  background: transparent !important;
  border: none !important; }

.ui-paginator .ui-paginator-page.ui-state-active {
  background: rgba(26, 57, 89, 0.9) !important; }

.ui-inputtext, input[_ngcontent-c7], .ui-inputtext[_ngcontent-c7], .ui-widget input {
  background: white !important;
  color: var(--appColor) !important;
  border-radius: 0 !important;
  font-family: "Roboto", "FontAwesome", "Trebuchet MS", Arial, Helvetica, sans-serif !important;
  padding: 0.31em 0.75em; }
  .ui-inputtext::placeholder, input[_ngcontent-c7]::placeholder, .ui-inputtext[_ngcontent-c7]::placeholder, .ui-widget input::placeholder {
    color: rgba(26, 57, 89, 0.5) !important; }
  .ui-inputtext::-webkit-input-placeholder, input[_ngcontent-c7]::-webkit-input-placeholder, .ui-inputtext[_ngcontent-c7]::-webkit-input-placeholder, .ui-widget input::-webkit-input-placeholder {
    color: rgba(26, 57, 89, 0.5) !important; }
  .ui-inputtext::-moz-placeholder, input[_ngcontent-c7]::-moz-placeholder, .ui-inputtext[_ngcontent-c7]::-moz-placeholder, .ui-widget input::-moz-placeholder {
    color: rgba(26, 57, 89, 0.5) !important; }
  .ui-inputtext:-ms-input-placeholder, input[_ngcontent-c7]:-ms-input-placeholder, .ui-inputtext[_ngcontent-c7]:-ms-input-placeholder, .ui-widget input:-ms-input-placeholder {
    color: rgba(26, 57, 89, 0.5) !important; }
  .ui-inputtext:-ms-input-placeholder:focus, input[_ngcontent-c7]:-ms-input-placeholder:focus, .ui-inputtext[_ngcontent-c7]:-ms-input-placeholder:focus, .ui-widget input:-ms-input-placeholder:focus {
    color: rgba(26, 57, 89, 0.5) !important; }
  .ui-inputtext:-moz-placeholder, input[_ngcontent-c7]:-moz-placeholder, .ui-inputtext[_ngcontent-c7]:-moz-placeholder, .ui-widget input:-moz-placeholder {
    color: rgba(26, 57, 89, 0.5) !important; }

.ui-chkbox-box.ui-state-active, .ui-dropdown-item.ui-state-highlight {
  background-color: var(--appColor) !important; }

.ui-radiobutton-box.ui-state-active {
  background: var(--appColor) !important; }

label {
  margin-bottom: 0 !important; }

.backgroundPrivado {
  background-color: rgba(22, 69, 30, 0.9) !important; }

.backgroundGoverno {
  background-color: rgba(26, 57, 89, 0.9) !important; }

.corPrivado {
  color: rgba(22, 69, 30, 0.9) !important; }

.corGoverno {
  color: rgba(26, 57, 89, 0.9) !important; }

/** overlay **/
p-overlaypanel .ui-panel-content, p-overlaypanel .ui-widget-content {
  background: transparent;
  border: none !important;
  border-radius: 0 !important;
  margin-top: 14px; }

.ui-overlaypanel-content {
  padding: 0 !important; }

/** overlay **/
/** botoes **/
button {
  height: 30px;
  text-transform: uppercase;
  border-radius: 50px !important; }

.ui-button {
  background-color: rgba(26, 57, 89, 0.9);
  border: none !important; }

.buttons > button {
  margin-right: 4px; }

.ui-spinner-button, .ui-calendar-button {
  background-color: var(--appColor) !important;
  border-radius: 0 !important; }

.ui-button-primary {
  background-color: rgba(26, 57, 89, 0.9) !important; }

.ui-button-success {
  background-color: #5a9732 !important; }

.ui-button-danger {
  background-color: rgba(180, 33, 10, 0.89) !important; }

.ui-button-attention {
  background-color: #ff885b !important; }

.ui-button-secondary {
  color: white !important;
  background-color: #6c757d !important; }

.ui-fileupload-choose.ui-button, .ui-fileupload-choose.ui-button .ui-widget, .ui-fileupload-choose.ui-button .ui-state-default {
  background-color: var(--appColor) !important; }

.ui-orderlist .ui-orderlist-controls .ui-button {
  background-color: var(--appColor) !important; }

.button-small {
  font-size: small !important; }

/** botoes **/
/*** calendar **/
.ui-datepicker .ui-widget-header,
.ui-datepicker .ui-datepicker-title {
  color: white !important; }

.ui-datepicker-header {
  background: var(--appColor) !important; }

.ui-datepicker-prev span, .ui-datepicker-next span {
  color: white !important; }

.footer-datepicker {
  text-align: center; }

.button-datapicker-ok .ui-button {
  background-color: #5a9732 !important;
  width: 30px !important;
  margin-right: 2.5px; }

.button-datapicker-cancel .ui-button {
  background-color: rgba(180, 33, 10, 0.89) !important;
  width: 30px !important;
  margin-left: 2.5px; }

/*** calendar **/
.ui-toolbar {
  padding: 0 !important;
  border-radius: 0 !important; }

/** breadcrumb **/
.ui-breadcrumb .ui-menuitem-text {
  font-size: 12px; }

.ui-breadcrumb ul li {
  display: inline-flex !important; }

.ui-breadcrumb {
  padding: 0 0 0 36px !important;
  background: transparent !important;
  border: none !important; }
  .ui-breadcrumb span, .ui-breadcrumb .pi {
    color: #e6e6e5 !important; }
  .ui-breadcrumb span:hover {
    color: #FFFFFF !important; }

/** breadcrumb **/
div.ui-table-summary.ui-widget-header.ng-star-inserted {
  background: transparent !important; }

.ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: #a5a5a5 !important; }

.ui-table .ui-sortable-column.ui-state-highlight {
  background-color: var(--appColor) !important; }

legend {
  width: auto !important;
  padding: 0.02em 1em !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  color: white !important;
  background-color: var(--appColor) !important;
  border-radius: 0 !important; }

.governo > .ui-fieldset > .ui-fieldset-legend {
  background-color: rgba(26, 57, 89, 0.9) !important; }

.privado > .ui-fieldset > .ui-fieldset-legend {
  background-color: rgba(22, 69, 30, 0.9) !important; }

.ui-fieldset-content[class*='ui-g-'] {
  padding: 0.5em 0 !important; }

form .ui-inputtext, form .ui-widget input {
  background-color: whitesmoke !important; }

.ui-fieldset {
  padding: 0.5em 0.4em !important; }

.ui-dropdown-trigger, label.ui-inputtext {
  border: 1px solid #e6e6e5 !important;
  background-color: whitesmoke !important;
  border-color: #e6e6e5 !important;
  color: var(--appColor) !important; }
  .ui-dropdown-trigger span.ui-dropdown-trigger-icon.ui-clickable.pi.pi-caret-down, label.ui-inputtext span.ui-dropdown-trigger-icon.ui-clickable.pi.pi-caret-down {
    font-size: 25px !important;
    color: #000 !important;
    right: 9px !important;
    left: unset !important; }

/*Se precisar que toda 'Seleção' esteja na cor $color_blue_dark, basta remover o '.ui-steps-item' do início*/
/*Se precisar que apenas a 'Seleção' do steps esteja na cor $color_blue_dark, basta incluir o '.ui-steps-item' no início*/
.ui-steps-item.ui-state-highlight {
  background-color: var(--appColor) !important; }

.ui-steps-item {
  width: 150px !important; }

/*** file upload ***/
div.ui-fileupload-buttonbar.ui-widget-header {
  background: transparent !important;
  border-radius: 0 !important; }

.ui-fileupload-buttonbar, .ui-corner-bottom {
  border: none !important; }

/*** file upload ***/
h2.titulo-index {
  color: #5a9732;
  font-weight: 800;
  font-size: 2.4rem; }

div.texto-index, p.texto-index {
  color: var(--appColor);
  font-weight: 600;
  font-size: 1.1rem; }

/*Footer*/
footer {
  line-height: 70px;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #FFFFFF;
  color: #FFFFFF; }

.badge {
  padding: 1px 9px 2px;
  font-size: 12.025px;
  font-weight: bold;
  white-space: nowrap;
  color: #FFFFFF;
  background-color: #999999;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px; }

.badge:hover {
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer; }

.badge-error {
  background-color: #b94a48; }

.badge-danger {
  background-color: #dc3545; }

.badge-error:hover, .badge-danger:hover {
  background-color: #953b39; }

.badge-warning {
  background-color: #f89406; }

.badge-warning:hover {
  background-color: #c67605; }

.badge-success {
  background-color: #468847; }

.badge-success:hover {
  background-color: #356635; }

.badge-info {
  background-color: #3a87ad; }

.badge-info:hover {
  background-color: #2d6987; }

.badge-inverse {
  background-color: #333333; }

.badge-inverse:hover {
  background-color: #1a1a1a; }

.ngx-gallery-icon {
  position: absolute !important;
  color: rgba(26, 57, 89, 0.9) !important; }

.ngx-gallery-preview-top .ngx-gallery-icon {
  color: #FFFFFF !important;
  position: relative !important; }

.ngx-gallery-arrow-left > .ngx-gallery-icon {
  left: -40px !important; }

.ngx-gallery-arrow-right > .ngx-gallery-icon {
  right: -40px !important; }

/*** ribbons ***/
/* The ribbons */
.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right; }

.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  box-shadow: 0 3px 10px -5px black;
  position: absolute;
  top: 19px;
  right: -21px; }

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent; }

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent; }

.ribbon.privado span {
  background: linear-gradient(#2e8e3d 0%, rgba(22, 69, 30, 0.9) 100%); }

.ribbon.privado span::before {
  border-left: 3px solid rgba(22, 69, 30, 0.9);
  border-top: 3px solid rgba(22, 69, 30, 0.9); }

.ribbon.privado span::after {
  border-right: 3px solid rgba(22, 69, 30, 0.9);
  border-top: 3px solid rgba(22, 69, 30, 0.9); }

.ribbon.governo span {
  background: linear-gradient(#275585 0%, rgba(26, 57, 89, 0.9) 100%); }

.ribbon.governo span::before {
  border-left: 3px solid rgba(26, 57, 89, 0.9);
  border-top: 3px solid rgba(26, 57, 89, 0.9); }

.ribbon.governo span::after {
  border-right: 3px solid rgba(26, 57, 89, 0.9);
  border-top: 3px solid rgba(26, 57, 89, 0.9); }

/*** ribbons ***/
/*** accordiontab ***/
p-accordiontab, .accordiontab {
  margin-bottom: 10px !important; }

.ui-accordion-header {
  margin-top: 10px !important; }

.ui-accordion .ui-accordion-header {
  background-color: #FFFFFF !important;
  border-radius: 14px;
  border-color: #e6e6e5;
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
  box-shadow: 0 20px 200px rgba(255, 255, 255, 0.25); }
  .ui-accordion .ui-accordion-header > a, .ui-accordion .ui-accordion-header > span {
    font-size: 16px;
    font-weight: bold;
    color: rgba(26, 57, 89, 0.99) !important; }
    .ui-accordion .ui-accordion-header > a > span, .ui-accordion .ui-accordion-header > span > span {
      color: #0092B1; }

.ui-accordion .ui-accordion-header.ui-state-active {
  border-top-left-radius: 14px !important;
  border-top-right-radius: 14px !important;
  border-top-color: #e6e6e5 !important;
  border-top-width: 1px !important;
  border-top-style: solid !important;
  border-bottom-color: #e6e6e5 !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important; }

.ui-accordion-content {
  border-bottom-left-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
  border-bottom-color: #e6e6e5 !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  margin-bottom: 10px !important; }
  .ui-accordion-content > p {
    color: #373839;
    text-align: justify;
    text-justify: inter-word !important; }

/*** accordiontab ***/
.registroNaoEncontrado {
  width: 100%;
  text-align: center; }

.titulo {
  font-size: 16px;
  font-weight: bold;
  color: #0092B1; }

.subtitulo {
  font-size: 14px;
  font-weight: bold; }

.conteudo {
  color: #373839;
  text-align: justify;
  font-size: 18px; }

.conteudoSubtitulo {
  color: #373839;
  text-align: justify;
  font-size: 16px; }

.campoInput {
  width: 100%; }

.corAnuncioInteresseSituacao1 {
  color: #437C35; }

.corAnuncioInteresseSituacao2 {
  color: #ff885b; }

.corAnuncioInteresseSituacao3 {
  color: darkred; }

.corAnuncioInteresseSituacao4 {
  color: #275585; }
